import Cookies from 'js-cookie'

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import user from "./modules/user";

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: "ASC1q2w3e4r",
});

export default createStore({
  state: () => ({
    user_id: "",
    domain: "",
    config: {},
    token: Cookies.get("token") || "",
  }),
  mutations: {
    setUserId(state, payload) {
      state.user_id = payload;
    },
    setSiteConfig(state, payload) {
      state.config = payload;
    },
    setDomain(state, domain ){
      state.domain = domain
    },
    setSsoDomain(state, domain){
      state.sso_domain = domain
    }
  },
  modules: {
    user,
  },
  plugins: [
    createPersistedState({
      key: "vuex",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
