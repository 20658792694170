import { getUserInfo, setUserTpl } from "@/api/user";

export default {
  namespaced: true,
  state: () => ({
    userInfo: null,
  }),
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setCusCard(state, payload) {
      state.userInfo.cus_card = payload;
    },
    setLevel(state, payload) {
      state.userInfo.level = payload;
    },
    setTpl(state, payload) {
      state.userInfo.nc_template = payload;
    },
  },
  actions: {
    async getUserInfo(context) {
      let params = {}
      //檢查LOCALSTORAGE是否有slashToken
      
      if(sessionStorage.getItem('slashToken')){
        params.slashToken = sessionStorage.getItem('slashToken');
      }
      var res = await getUserInfo(params);
      sessionStorage.removeItem('slashToken');

      if (res.code === 200) {
        context.commit("setUserInfo", res.data);
        return true;
      }else{
        return false;
      }
    },
    async setUserTpl(context, payload) {
      var res = await setUserTpl(payload);
      if (res.code === 200) {
        context.commit("setTpl", payload);
      }
    },
  },
};
