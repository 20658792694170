import ajax from "./ajax";

export const getUserInfo = async (params) => ajax(`/user/getUserInfo`,params,'POST');

export const setUserLevel = async (level) =>
  ajax(`/user/setUserLevel`, { level }, "POST");

export const setUserTpl = async (tpl) =>
  ajax(`/user/setUserTpl`, { tpl }, "POST");

export const updateCard = async (userInfo) =>
  ajax(`/card/updateCard`, userInfo, "POST");

export const getUserCompany = async () => ajax(`/user/getUserCompany`);
export const addUserCompany = async (params) => ajax(`/user/addUserCompany`,params,'POST');
export const updateUserCompany = async (params) => ajax(`/user/updateUserCompany`,params,'POST');
export const deleteUserCompany = async (id) => ajax(`/user/deleteUserCompany`,{id},'POST');
export const setUCDefault = async (id) => ajax(`/user/setUCDefault`,{id},'POST');

export const getUserFavorite = async () => ajax(`/user/getFavorite`);
export const getUserConnections = async () => ajax(`/user/getConnections`);
export const addFavorite = async (userid)=> ajax(`/user/addFavorite`,{userid},"POST")
export const delFavorite = async (id)=> ajax(`/user/delFavorite`,{id},"POST")

