import axios from 'axios'
import Cookies from 'js-cookie'

import store from '../store'
import { Toast } from 'vant'

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000,
    withCredentials: true
})

instance.interceptors.request.use(
    config => {
        // if(config.data.showloading || config.params.showloading){
        //     console.log('showloading')
        // }
        if(config.requestBase=='sso'){
            config.baseURL = process.env.VUE_APP_SSO_URL
        }

        if (Cookies.get('token')) {  // 判斷是否存在token，如果存在的話，則每個http header都加上token
            config.headers.Authorization = `${Cookies.get('token')}`;
        }


        if (config.method === 'post') {
            
            if(Cookies.get('uid')){
                config.data = {        
                    uid: Cookies.get('uid'),
                    ...config.data
                }      
            }

            // if(config.data.showloading){
            //     console.log('showloading')
            //     delete config.data.showloading
            // }

        } else if (config.method === 'get') {
        
            config.params = {        
                uid: Cookies.get('uid'),
                ...config.params
            }

            // if(config.params.showloading){
            //     console.log('showloading')
            //     delete config.params.showloading
            // }

        }

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

instance.interceptors.response.use(
    response => {
        refreshToken(response)
        return response
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 返回 401 清除token資訊並跳轉到登入頁面
                    Cookies.remove('token',{ domain: store.state.sso_domain})
                    Cookies.remove('uid',{ domain: store.state.sso_domain})
                  
                    store.commit(types.LOGOUT);
                    
                    router.replace({
                        path: 'login',
                        query: {redirect: router.currentRoute.fullPath}
                    })
            }
        }
        if(!navigator.onLine){
            Toast('網路斷線，請重試')
            return
        }
        return Promise.reject(error)   // 返回介面返回的錯誤資訊
});

function refreshToken(response) {
    let token = response.headers.authorization
    if (token) {
        console.log('change token')
        Cookies.set('token', token , { expires: 365 ,domain: store.state.sso_domain});
    }
}

export default async function ajax( url , data={} , type='GET' , option={} ){
    
    let result
    if(type.toUpperCase() === 'GET' ){
        let queryStr = ''

        Object.keys(data).forEach(key=>{
            queryStr +=  key + '=' + data[key] + '&'
        })

        if(queryStr !== ''){
            queryStr = queryStr.substring(0,queryStr.lastIndexOf('&'))
            url += '?' + queryStr
        }

        result = await instance.get(url, option)
    }else{

        result = await instance.post(url,data,option)
    }
    return result.data
}

/*生成指定長度的隨機數*/
function randomCode(length) {
    let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let result = "";
    for (let i = 0; i < length; i++) {
        let index = Math.ceil(Math.random() * 9);
        result += chars[index];
    }
    return result;
}