<template>
  <header class="header">
    <div class="logo-wrap">
      <!-- <i class="icon-menu icli nav-bar" @click="toggleMenu"></i> -->
      <a href="###">
        <img class="logo" src="@/assets/images/logo/logo_w.jpg" alt="logo" />
      </a>
    </div>
    <div class="avatar-wrap" @click="$router.push('/cart')">
      <!-- <span class="font-sm"><i class="iconly-Location icli font-xl"></i> Los Angeles</span> -->
      <!-- <van-icon name="cart-o" :badge="cartStore.cartNum" /> -->
    </div>
  </header>

  <!-- Main Start -->
  <main class="main-wrap account-page mb-xxl">
    <div class="account-wrap section-b-t">
      <div class="user-panel">
        <div class="media">
          <a href="javascript:void(0)">
            <img
              :src="userInfo.line_picture || require('@/assets/images/avatar.png')"
              alt="avatar"
            />
          </a>
          <div class="media-body">
            <a href="javascript:void(0)" class="title-color">
              {{ userInfo.real_name }}
              <span class="content-color font-sm"
                >歡迎來到電子名片會員中心</span
              >
            </a>
          </div>
          <!-- <div class="media-right" @click="$router.push('/user/setting')"> -->
          <!-- <i class="icon-pencil"></i> -->
          <!-- </div> -->
        </div>
        <div class="user-info-block">
          <div class="user-info" @click="router.push('/member')">
            <div>
              <i class="icon-pencil"></i>
              修改資料
            </div>
            <div>修改名片資料</div>
          </div>
          <div class="user-info" @click="goCardEdit">
            <div>
              <i class="icon-add-chart"></i>
              商務卡片
            </div>
            <div>設計商務卡片</div>
          </div>
          <div class="user-info" @click="goShop">
            <div>
              <i class="icon-cart-variant"></i>
              立即購買
            </div>
            <div>付費開通名片</div>
          </div>
          <div class="user-info" @click="handleShowNfc">
            <div>
              <i class="icon-qrcode"></i>
              展示二維碼
            </div>
            <div>提供好友掃瞄</div>
          </div>
        </div>
      </div>

      <!-- Navigation Start -->
      <ul class="navigation">
        <li>
          <a href="javascript:;" class="nav-link title-color font-sm">
            <i class="icon-star icli"></i>
            <span>會員編號</span>
          </a>
          <a href="javascript:;">
            {{ userInfo.user_id }}
          </a>
        </li>
        <li>
          <a href="javascript:;" class="nav-link title-color font-sm">
            <i class="icon-account icli"></i>
            <span>姓名</span>
          </a>
          <a href="javascript:;">
            {{ userInfo.real_name }}
          </a>
        </li>
        <li>
          <a href="javascript:;" class="nav-link title-color font-sm">
            <i class="icon-corporate icli"></i>
            <span>公司名稱</span>
          </a>
          <a href="javascript:;">
            {{ userInfo.company }}
          </a>
        </li>
        <li>
          <a href="javascript:;" class="nav-link title-color font-sm">
            <i class="icon-heart icli"></i>
            <span>會員等級</span>
          </a>
          <a href="javascript:;">
            {{ userInfo.level_name }}
          </a>
        </li>
        <li>
          <a href="javascript:;" class="nav-link title-color font-sm">
            <i class="icon-camera-timer icli"></i>
            <span>使用期限</span>
          </a>
          <a href="javascript:;">
            {{ overdue }}
          </a>
        </li>
        <li>
          <a href="javacript:void(0)" class="nav-link title-color font-sm">
            <i class="icon-link-variant icli"></i>
            <span>名片連結</span>
          </a>
          <!-- <a href="javacript:void(0)"> -->
          <button
            v-clipboard:copy="userInfo.nfcurl"
            v-clipboard:success="onSuccess"
            v-clipboard:error="onError"
          >
            複製
          </button>
          <!-- </a> -->
        </li>
      </ul>
      <!-- Navigation End -->
      <button
        class="log-out"
        data-bs-toggle="offcanvas"
        data-bs-target="#confirmation"
        aria-controls="confirmation"
        @click="handleLogout"
      >
        <i class="icon-logout icli"></i>
        登出
      </button>
    </div>
  </main>
  <!-- Main End -->
  <!-- Footer Start -->
  <Footer />
  <!-- Footer End -->
  <van-dialog
    v-model:show="showNfcQrcode"
    title="電子名片二維碼"
    :show-cancel-button="true"
    cancel-button-text="關閉"
    :show-confirm-button="false"
  >
    <div class="qrcode">
      <qrcode-vue :value="userInfo.nfcurl" size="200" level="M" />
    </div>
  </van-dialog>

  <!-- Pwa Install App Popup Start -->
  <div
    class="offcanvas offcanvas-bottom addtohome-popup"
    :class="{ show: showPwaInstall }"
    tabindex="-1"
    id="offcanvas"
  >
    <div class="offcanvas-body small">
      <div class="app-info">
        <img src="@/assets/images/logo/logo48.png" class="img-fluid" alt="" />
        <div class="content">
          <h3>
            SlashCard <i data-feather="x" data-bs-dismiss="offcanvas"></i>
          </h3>
          <a href="javascript:;">加入主畫面會讓您使用更方便喔</a>
        </div>
      </div>
      <button
        class="btn-solid install-app"
        id="installApp"
        @click="handlePwaInstall"
      >
        點擊加入主畫面
      </button>
    </div>
  </div>
  <!-- Pwa Install App Popup End -->
  <!-- <div class="floating-message">
    <p>加入主畫面會更加方便</p>
    <button>加入主畫面</button>
  </div> -->
</template>

<script setup>
import liff from "@line/liff";

import Cookies from "js-cookie";
import moment from "moment";

import QrcodeVue from "qrcode.vue";

import { toClipboard } from "@soerenmartius/vue3-clipboard";

import { ref, computed, onMounted, onBeforeMount, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { Toast } from "vant";
import { Dialog } from "vant";

import Footer from "@/components/Footer";

import { linelogin, bindCard } from "@/api";

const router = useRouter();
const route = useRoute();
const store = useStore();

const showShare = ref(false);

const showNfcQrcode = ref(false);

const imageUrl = ref(process.env.VUE_APP_IMAGE_URL);

const userLevel = {
  0: "未付費會員",
  1: "付費會員",
  2: "試用用戶"
};

const is_due = ref(false);

const showPwaInstall = ref(false);
const deferredPrompt = ref(null);

onBeforeMount(async () => {
  let res = await store.dispatch("user/getUserInfo");

  if(!res){
    router.push('/login')
  }
});

onMounted(() => {
  if (localStorage.getItem("pwaInstall")) {
    showPwaInstall.value = false;
  } else {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      deferredPrompt.value = event;
      showPwaInstall.value = true;
    });
  }
});

const userInfo = computed(() => {
  return store.state.user.userInfo;
});

const nfcurl = computed(() => {
  return encodeURI(store.state.user.userInfo.ufcurl);
});

const overdue = computed(() => {
  if (store.state.user.userInfo.overdue_time > 0) {
    return moment
      .unix(store.state.user.userInfo.overdue_time)
      .format("YYYY-MM-DD");
  } else {
    return "無期限";
  }
});

const goCardEdit = () => {
  router.push("/card/edit");
};

const onSelect = (option) => {
  Toast(option.name);
  showShare.value = false;
};

const handleShowNfc = () => {
  showNfcQrcode.value = true;
};

const doCopy = () => {
  toClipboard(userInfo.value.nfcurl);
  Toast("已放入剪貼簿");
};

const onSuccess = () => {
  Toast("已放入剪貼簿");
};

const onError = () => {
  Toast("操作失敗");
};

const doCopyUid = () => {
  toClipboard(userInfo.value.user_id);
  Toast("已放入剪貼簿");
};

const bindTggo = () => {
  console.log(userInfo.value);
  let url = `https://www.tggo.com.tw/u.cgi?&mnm=mybinding&ncode=${userInfo.value.uniqid}&name=${userInfo.value.real_name}&openExternalBrowser=1`;

  window.open(url, "_blank");
};

const handleLogout = () => {
  Cookies.remove("token", { domain: store.state.sso_domain });
  Cookies.remove("uid", { domain: store.state.sso_domain });
  // if (liff.isLoggedIn()) {
  //   liff.logout()
  // }
  router.push("/login");
};

const goShop = () => {
  window.open("https://shop."+store.state.sso_domain+"/m/card", "_blank");
};

const handlePwaInstall = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  if (isMobile && !window.navigator.standalone) {
    window.navigator.standalone = true;
    if (deferredPrompt.value) {
      deferredPrompt.value.prompt();
      deferredPrompt.value.userChoice.then(function (choiceResult) {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt.value = null;
      });
    }
    // window.prompt("請點擊右上角的「加到主畫面」以將此網頁加入您的手機主畫面");
  }
};
</script>

<style src="@/assets/css/style.css"></style>

<style lang="less" scoped>
.media {
  .media-body {
    width: 100%;
  }
  .media-right {
    width: 100%;
    vertical-align: text-top;
    text-align: right;
    i {
      font-size: 20px;
    }
  }
}
.user-info-block {
  box-sizing: content-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .user-info {
    // flex: 1;
    border: 1px gray solid;
    border-radius: 8px;
    margin: 5px 0;
    padding: 5px 10px;
    width: 48%;
    div:nth-child(1) {
      font-size: 16px;
    }
    div:nth-child(2) {
      color: #999;
    }
  }
}

.qrcode {
  text-align: center;
}

.floating-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  z-index: 9999;
}

.floating-message p {
  margin: 0 0 10px 0;
}

.floating-message button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
}

.floating-message:before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
  border-top-color: #fff;
}
</style>
