// import { v1 as uuidv1 } from 'uuid'
import Cookies from 'js-cookie'
// import { useShopStore } from "@/store/Shop"
// import { useUserStore } from "@/store/User"
// import { useCartStore } from "@/store/Cart"

import store from '@/store/index.js'

export async function initSession() {
    // if(!Cookies.get('SessionId')){
    //     Cookies.set('SessionId',uuidv1())
    // }
}

export async function initStore() {
    // 獲取當前頁面的主機名
    const hostname = window.location.hostname;

    //判斷hostname是否為ip
    const reg = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
    if(reg.test(hostname)){
        store.commit('setDomain',{domain:hostname})
        return
    }
    // 將主機名按照句點進行拆分
    const parts = hostname.split('.');
    let domain = '';
    // 如果主機名包含至少 3 個部分（例如 subdomain.example.com）
    if (parts.length >= 3) {
        // 獲取第二個部分（即 subdomain）
        domain = parts[1]+'.'+parts[2];
    }else{
        domain = hostname;
    }
    store.commit('setSsoDomain',domain)
    store.commit('setDomain',hostname)
}
