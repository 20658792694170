import ajax from "./ajax";

export const getSiteConfig = async () => ajax(`/auth/getSiteConfig`);

export const linelogin = async (params) => {
  return ajax(`/auth/lineLogin`, params, "POST", {requestBase:'sso'})
};

//檢查手機否為會員

export const checkPhone = async (params) =>
{
  return ajax(`/auth/checkPhone`, params, "POST", {requestBase:'sso'})
}

export const bindCard = async (params) =>
  ajax(`/auth/bindCard`, params, "POST");

export const checkLineId = async (lineid) =>
  ajax(`/auth/checkLineId`, { lineid }, "GET");

export const register = async (userInfo) =>
  ajax(`/auth/register`, userInfo, "POST");

export const updateCusCard = async (params) =>
  ajax(`/card/updateCusCard`, params, "POST");

//取得名片資料
export const getCard = async (params) => ajax(`/card/getCard`, params, "GET");

export const getCusCard = async (params) =>
  ajax(`/card/getCusCard`, params, "GET");

export const getVipCard = async (params) =>
  ajax(`/card/getVipCard`, params, "GET");

export const getCard1 = async (userid) =>
  ajax(`/card/getCard1`, { userid }, "GET");

export const updateSendCount = async (userid) =>
  ajax(`/user/updateSendCount`, { userid }, "GET");

//授權使用者
export const setAuthUser = async (params) =>
  ajax(`/user/setAuthUser`, params , "POST");

export const getAuthUsers = async () =>
  ajax(`/user/getAuthUsers`);

export const delAuthUser = async (id) =>
  ajax(`/user/delAuthUser`,{id});

export const getAuthList = async () =>
  ajax(`/user/getAuthList`);

