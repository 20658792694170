import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { vant } from "@/plugins/vant";

import { VueClipboard } from "@soerenmartius/vue3-clipboard";

import "@/assets/css/normalize.css";
import "@/assets/css/common.less";
import Cookies from "js-cookie";
// import './registerServiceWorker'

import { initStore, initSession } from './utils/init'

(async function init() {
    //使用URLSearchParams API取得query string的refer參數,並寫到Cookie
    const urlParams = new URLSearchParams(window.location.search);
    //取得refer參數
    const refer = urlParams.get('refer');

    //將refer參數寫入Cookie
    if (!Cookies.get('refer_code') && refer) {
        Cookies.set('refer_code', refer, { expires: 1 });
    }
    await initStore()
})()



const vue = createApp(App);

vant(vue);
vue.use(VueClipboard);
vue.use(store)
vue.use(router)
vue.mount("#app");
